import { Component } from 'react'
// import { navigate } from 'gatsby'

import { withSearchIndex } from './search-index'
import { LocationService } from '@renderbus/common/service'
import { SEARCH_FILEDS } from '@renderbus/common/components'

class SearchEngine extends Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      query: '',
    }
    this.index = this.props.searchQuery
    this.allResults = Object.values(this.index.documentStore.docs).filter(
      result => result[SEARCH_FILEDS.qa.slug],
    )
    this.stopWordsReg = /[,./?!_()[\]~`;:"'|^&<>/\\]/g
    // 匹配这些中文标点符号 。 ？ ！ ， 、 ； ： “ ” ‘ ' （ ） 《 》 〈 〉 【 】 『 』 「 」 ﹃ ﹄ 〔 〕 … — ～ ﹏ ￥
    this.zhCNstopWordsReg = /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g
  }

  getResultBySearchLanguage(query) {
    let result = []
    // TODO: word segmentation for chinese
    if (query.match(/[\u4e00-\u9fa5]+/) && query.search(/[^\u4e00-\u9fa5\s]/) < 0) {
      let queryWords = query
        .trim()
        .split(/[\s,./?!_()[\]~`;:"'|^&<>/]/)
        .filter(item => item)
      let zhResult = []
      Object.values(this.index.documentStore.docs).forEach(doc => {
        queryWords.forEach(word => {
          if (
            doc[SEARCH_FILEDS.qa.question].includes(word) ||
            doc[SEARCH_FILEDS.qa.answer].answer.includes(word) ||
            doc[SEARCH_FILEDS.qa.tags].some(item => item.name.includes(word))
          ) {
            zhResult.push(doc)
          }
        })
      })
      result = Array.from(new Set(zhResult))
    } else {
      result = this.index
        .search(query, { expand: true })
        .map(({ ref }) => this.index.documentStore.getDoc(ref))
    }
    return result.filter(item => item[SEARCH_FILEDS.qa.slug])
  }

  reSearch = value => {
    let query = value
    if (value) {
      query = value.replace(this.stopWordsReg, ' ')
      query = query.replace(this.zhCNstopWordsReg, ' ')
    }
    let result = []
    if (!query) {
      result = this.allResults
    } else {
      result = this.getResultBySearchLanguage(query)
    }
    this.setState({
      query: query,
      results: result.slice(0, 6),
    })
  }

  updateUrl = value => {
    const searchParams = {
      query: value,
      page: 1,
    }
    const currentLocationUrl = LocationService.updateLocationSearchString(searchParams, '/search')
    window.open(currentLocationUrl)
    // navigate(currentLocationUrl, { state: { newPage: 1 } })
  }

  handleChange = value => {
    this.reSearch(value)
  }

  handleSearch = value => {
    if (!value.trim()) return
    this.reSearch(value)
    this.updateUrl(value)
  }

  componentDidMount() {
    const searchKey = LocationService.getLocationSearchParams().get('query')
    this.reSearch(searchKey)
  }

  render() {
    return this.props.render({
      query: this.state.query,
      result: this.state.results,
      handleChange: this.handleChange,
      handleSearch: this.handleSearch,
      type: this.props.type,
    })
  }
}

export default withSearchIndex(SearchEngine)
