export function sortByTime(array) {
  return (array = array.sort((newA, newB) => {
    let dateA = null
    let dateB = null
    if (newA.legacyDate) {
      dateA = new Date(newA.legacyDate)
    } else {
      dateA = new Date(newA.createdAt)
    }
    if (newB.legacyDate) {
      dateB = new Date(newB.legacyDate)
    } else {
      dateB = new Date(newB.createdAt)
    }
    return dateB - dateA
  }))
}
