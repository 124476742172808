export const SEARCH_FILEDS = {
  news: {
    title: 'newsTitle',
    slug: 'newsSlug',
    date: 'newsDate',
    category: 'newsCategory',
    content: 'newsContent',
  },
  support: {
    title: 'supportTitle',
    slug: 'supportSlug',
    abstract: 'supportAbstract',
    content: 'supportContent',
  },
  qa: {
    question: 'question',
    slug: 'slug',
    tags: 'tags',
    answer: 'answer',
  },
}
