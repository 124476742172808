import { stripUnit } from 'polished'
import styled from 'styled-components'

import shadows from '../../theme/shadows'
import { spacing, typography, color } from '../../theme/variables'
import { fadeIn, fadeOut, scaleIn, scaleOut } from '../../theme/keyframes'
import Flex from '../flex'
import { transition } from '../../theme'

export const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  touch-action: none;
  transform: scale(1);
  background-color: rgba(0, 0, 0, 0.5);
  &.entering {
    animation: ${fadeIn} 300ms;
  }
  &.exiting {
    animation: ${fadeOut} 300ms;
  }
`
export const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &.entering {
    animation: ${scaleIn} 300ms;
  }
  &.exiting {
    animation: ${scaleOut} 300ms;
  }
`
export const Header = styled.div`
  position: relative;
  padding: ${spacing.small} ${spacing.base};
  > h6 {
    margin: 0;
    height: 2em;
    line-height: 2em;
    color: ${p => (p.applyTitle ? color.primary : color.text)};
    font-size: ${p => (p.applyTitle ? '30px' : typography.h2)};
    text-align: ${p => p.applyTitle && 'center'};
  }
`
export const Content = styled.div`
  position: relative;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  margin: ${spacing.base};
  box-shadow: ${shadows[3]};
  max-height: calc(100% - ${2 * stripUnit(spacing.base)}px);
  color: ${props => (props.darkTheme ? 'white' : color.input)};
  background-color: ${props => (props.darkTheme ? color.panel : 'white')};
  @media (min-width: 500px) {
    min-width: ${props => props.minWidth};
  }
`
export const Spotlight = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: ${spacing.base};
`
export const RightClose = styled.div`
  position: absolute;
  top: ${spacing.small};
  right: ${spacing.base};
`

export const SimpleHeader = styled(Flex)`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 20px;
  justify-content: space-between;
  z-index: 2;
  @media (max-width: 768px) {
    padding: 10px;
  }
`

export const SimpleCloseButton = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: rgb(0, 0, 0, 0.5);
  opacity: 0.6;
  cursor: pointer;
  transition: ${transition('opacity')};
  :hover {
    opacity: 1;
  }
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`

export const BaseHeader = styled(Flex)`
  height: 40px;
  background-color: #333;
  justify-content: space-between;
  padding-left: 25px;
  .close-wrap {
    width: 20px;
  }
`
export const BaseHeaderTitle = styled.p`
  color: #fff;
  font-size: 16px;
  line-height: 28px;
`
export const CloseWrapper = styled(RightClose)`
  top: 5px;
  right: 20px;
`
export const BaseModalContent = styled.div`
  padding: 30px 35px;
`
