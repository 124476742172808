import { getDomainName } from './domain'
import { checkBrowser } from '@renderbus/common/service'
/* eslint-disable */
export default {
  /**
   * @desc name  cookiekey
   */
  getCookie(name) {
    if (!checkBrowser()) return
    var cookieValue = null
    if (document.cookie && document.cookie != '') {
      var cookies = document.cookie.split(';')
      for (var i = 0; i < cookies.length; i++) {
        // var cookie = jQuery.trim(cookies[i]);
        var cookie = cookies[i].replace(/^\s*|\s*$/g, '')
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) == name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
          break
        }
      }
    }
    return cookieValue
  },
  /**
   * @desc name  cookiekey
   */
  setCookie(name, value, Days = 356, currentDomain = true) {
    if (!checkBrowser()) return
    const date = new Date().setDate(new Date().getDate() + Days)
    document.cookie = `${name}=${value};expires=${date};${
      currentDomain ? `domain=.renderbus.com;` : ''
    }path=/`
  },
  /**
   * @desc name  cookiekey
   */
  delCookie(name) {
    if (!checkBrowser()) return
    var exp = new Date()
    exp.setTime(exp.getTime() - 1)
    var cval = func.getCookie(name)
    if (cval != null) document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString()
  },
}
