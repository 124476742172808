import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const SEO = ({ title, keywords, description, shareImgSrc, sharePostSlug, type, canonicalUrl }) => (
  <Helmet title={title}>
    <meta name='keywords' content={keywords} />
    <meta name='applicable-device' content='pc,mobile' />
    <meta name='description' content={description} />
    <meta property='og:image' content={`https:${shareImgSrc}`} />
    <meta property='og:url' content={`https://www.renderbus.com/${sharePostSlug}`} />
    <meta property='og:title' content={title} />
    <meta property='og:description' content={description} />
    <meta property='og:type' content={type} />
    {canonicalUrl && (
      <link
        rel='canonical'
        href={`https://www.renderbus.com${canonicalUrl}`}
        data-react-helmet='true'
      ></link>
    )}
  </Helmet>
)

SEO.propTypes = {
  title: PropTypes.string,
  keywords: PropTypes.string,
  description: PropTypes.string,
  shareImgSrc: PropTypes.string,
  sharePostSlug: PropTypes.string,
  type: PropTypes.string,
}

SEO.defaultProps = {
  title: 'Renderbus云渲染农场-海量机器云渲染平台,高效3D渲染云服务',
  keywords: '',
  description:
    'Renderbus云渲染农场,24H自助式云渲染平台/奥斯卡作品云渲染农场,全面支持3dsMax,Maya,Vray,Arnold等软件及插件,提供免费云渲染体验,渲染低至0.07元.',
  shareImgSrc: '//task.renderbus.com/static/og/renderbus-og.png',
  sharePostSlug: '',
  type: 'website',
}

export default SEO
