import React from 'react'

import Flex from '../flex'
import Logo from '../svg/logo'
import { Icon, IconType } from '../icon'
import { useScroll } from '../../hooks'
import NavigationLink from './navigation-link'
import Button from '../button'
import MobileMenu from './moblie-menu'
import { API, Axios } from '../../utils'
import { UserAuthorizationService } from '../../service'
import {
  Navbar,
  Brand,
  StyledWrapper,
  MenuIcon,
  CloseIcon,
  IconWrap,
  DropdownMenu,
  DropdownBlockWrap,
  DropdownBlock,
  DropdownWrap,
  AccountContainer,
  AccountDetail,
  UserMsg,
  UserAvatar,
  LoginButton,
  LoginLink,
  RegisterButton,
  SubText,
  QrCodeBlock,
} from './atoms'
import ProductIcon1 from '@renderbus/common/components/svg/product-01'
import ProductIcon2 from '@renderbus/common/components/svg/product-02'
import ProductIcon3 from '@renderbus/common/components/svg/product-03'
import ProductIcon4 from '@renderbus/common/components/svg/product-04'
import ProductIcon5 from '@renderbus/common/components/svg/product-05'
import DownloadIcon1 from '@renderbus/common/components/svg/download-01'
import DownloadIcon2 from '@renderbus/common/components/svg/download-02'
import CGIcon01 from '@renderbus/common/components/svg/cg-01'
import CGIcon02 from '@renderbus/common/components/svg/cg-02'
import QRCode from '@renderbus/main/src/images/download/qr-code-header.png'
import { isTest, baseLink } from '@renderbus/common/service'

function ScrollHandle({ handler }) {
  useScroll(handler)
  return null
}
const instance = isTest() ? new Axios(API.auth.testHost) : new Axios(API.auth.host)
const ipRenderbus = isTest() ? 'https://ip-pre.renderbus.com/' : 'https://ip.renderbus.com/'
const accountLink = isTest()
  ? 'https://account-pre.renderbus.com/'
  : 'https://account.renderbus.com/'

class Header extends React.PureComponent {
  static leftPart = [
    {
      link: '/',
      text: '首页',
      alt: '云渲染首页',
    },
    {
      text: '产品与服务',
      isBlock: true,
      children: [
        {
          title: '影视动画渲染',
          text: '为影视行业提供澎湃算力',
          link: '/film-tv-rendering.html',
          icon: <ProductIcon1 />,
          id: 'ysdhxr',
        },
        {
          title: '在线版权登记',
          text: '区块链存证+国家版权局登记',
          isOtherWeb: true,
          link: ipRenderbus,
          icon: <ProductIcon2 />,
          id: 'zxbqdj',
        },
        {
          title: '效果图渲染',
          text: '会员一键提交免费渲染',
          link: '/architectural-rendering.html',
          icon: <ProductIcon3 />,
          id: 'xgtxr',
        },
        {
          title: '瑞兔百宝箱',
          text: '提升工作效率，免费使用',
          link: '/raytools.html',
          icon: <ProductIcon4 />,
          id: 'nav-raytools',
        },
        {
          title: '云渲染管理系统',
          text: '三步操作实现私有化部署',
          link: '/collaborative-cloud-production.html',
          icon: <ProductIcon5 />,
          id: 'yxrglxt',
        },
      ],
    },
    {
      text: '价格',
      children: [
        {
          text: '影视动画价格',
          link: '/pricing.html',
        },
        {
          text: '效果图价格',
          link: '/effect_price.html',
        },
      ],
    },
    {
      link: '/client-works.html',
      text: '案例',
    },
    {
      link: '/news/',
      text: '新闻',
      target: '_blank',
    },
    {
      text: 'CG社区',
      isBlock: true,
      className: 'cg-community',
      children: [
        {
          title: '泛CG交流会',
          text: '属于CG人的自由分享、交流平台',
          link: 'https://cgtalks.renderbus.com/',
          isOtherWeb: true,
          icon: <CGIcon01 />,
          id: 'nav-cgtalks',
        },
        {
          title: '渲染大赛',
          text: '国内超火的3D渲染动画大赛，千万曝光、奖池丰厚',
          link: '/rc2024.html',
          icon: <CGIcon02 />,
          id: 'nav-cgchallenge',
        },
      ],
    },
    {
      link: '/go-cloud.html',
      text: '青云平台',
      id: 'nav-qcloud',
      subText: '惠',
    },
  ]
  static rightPart = [
    {
      text: '下载',
      isBlock: true,
      children: [
        {
          title: '下载动画客户端',
          text: '影视动画云渲染用户专用',
          link: '/download.html',
          icon: <DownloadIcon1 />,
          id: 'dhdesk',
        },
        {
          title: '下载效果图客户端',
          text: '效果图云渲染用户专用',
          link: '/xgt-download.html',
          icon: <DownloadIcon2 />,
          id: 'xgtdesk',
        },
      ],
    },
    {
      link: '/support-new/',
      text: '帮助/教程',
      target: '_blank',
    },
  ]
  static taskLinkList = [
    {
      link: `${baseLink()}`,
      text: '我的作业',
    },
    {
      link: `${accountLink}center/user/topUp`,
      text: '立即充值',
    },
    {
      link: `${accountLink}center/user/consumption`,
      text: '消费清单',
    },
    {
      link: `${accountLink}center/user/profile`,
      text: '基本资料',
    },
  ]
  state = {
    showMobileMenu: false,
    left: 0,
    isTriggerLogout: false,
    currentUserName: this.props.userName,
  }
  handleMenuClick = () => {
    this.setState(preState => ({ showMobileMenu: !preState.showMobileMenu }))
  }
  handleScroll = e => {
    this.setState({ left: -e.target.scrollingElement.scrollLeft })
  }

  handleLogout = async () => {
    await instance
      .post(API.auth.logout, {
        raySyncUserKey: '',
      })
      .then(res => {
        if (res.code === 200) {
          UserAuthorizationService.removeUserName()
          UserAuthorizationService.removeIsPictureUser()
          this.setState({ currentUserName: '' })
        }
      })
  }
  redirectToAuth() {
    instance.post(API.auth.getAuthToken).then(res => {
      if (res.code === 200) {
        window.open(`${accountLink}authenticate?token=${res.data}`)
      }
    })
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ currentUserName: nextProps.userName })
  }

  render() {
    const { location } = this.props
    const { showMobileMenu, left, currentUserName } = this.state
    const isUserLogin = !this.state.isTriggerLogout && currentUserName
    return (
      <React.Fragment>
        <ScrollHandle handler={this.handleScroll} />
        <Navbar style={{ left }}>
          <StyledWrapper>
            <Brand href='/'>
              <Logo alt='Renderbus云渲染农场-您强大的云渲染部队' />
            </Brand>
            <Flex halign='space-between' grow={2} className='hide-at-mobile'>
              <div>
                {Header.leftPart.map((l, i) =>
                  l.children ? (
                    <DropdownMenu
                      key={i}
                      isActive={l.children.map(o => o.link).includes(location.pathname)}
                    >
                      <span>{l.text}</span>
                      {l.isBlock ? (
                        <DropdownBlockWrap>
                          {l.children.map((c, ci) => {
                            return c.isOtherWeb ? (
                              <DropdownBlock
                                as='a'
                                id={c.id}
                                key={ci}
                                href={c.link}
                                target='_blank'
                                isActive={location.pathname === c.link}
                              >
                                <div className='icon'>{c.icon}</div>
                                <div className='text'>
                                  <p>{c.title}</p>
                                  <p>{c.text}</p>
                                </div>
                              </DropdownBlock>
                            ) : (
                              <DropdownBlock
                                as={NavigationLink}
                                id={c.id}
                                key={ci}
                                to={c.link}
                                style={{ padding: '0' }}
                                isActive={location.pathname === c.link}
                                className={l.className}
                              >
                                <div className='icon'>{c.icon}</div>
                                <div className='text'>
                                  <p>{c.title}</p>
                                  <p className='sub-text'>{c.text}</p>
                                </div>
                              </DropdownBlock>
                            )
                          })}
                        </DropdownBlockWrap>
                      ) : (
                        <DropdownWrap>
                          {l.children.map((c, ci) => (
                            <NavigationLink
                              key={ci}
                              to={c.link}
                              title={c.text}
                              isActive={location.pathname === c.link}
                              target={c.target}
                              isOtherWeb={c.isOtherWeb}
                            >
                              {c.text}
                            </NavigationLink>
                          ))}
                        </DropdownWrap>
                      )}
                    </DropdownMenu>
                  ) : (
                    <NavigationLink
                      key={i}
                      to={l.link}
                      title={l.alt || l.text}
                      isActive={location.pathname === l.link}
                      target={l.target}
                      id={l.id}
                      rel={l.rel}
                      isOtherWeb={l.isOtherWeb}
                    >
                      {l.text}
                      {l.subText && <SubText>{l.subText}</SubText>}
                    </NavigationLink>
                  ),
                )}
              </div>
              <div>
                {Header.rightPart.map((l, i) =>
                  l.children ? (
                    <DropdownMenu
                      key={i}
                      isActive={l.children.map(o => o.link).includes(location.pathname)}
                    >
                      <span>{l.text}</span>
                      {l.isBlock ? (
                        <DropdownBlockWrap className='right-dropdown'>
                          {l.children.map((c, ci) => {
                            return (
                              <DropdownBlock
                                as={NavigationLink}
                                id={c.id}
                                key={ci}
                                to={c.link}
                                style={{ padding: '0' }}
                                isActive={location.pathname === c.link}
                              >
                                <div className='right-icon'>{c.icon}</div>
                                <div className='text'>
                                  <p
                                    className={
                                      c.link === '/xgt-download.html'
                                        ? 'is-picture'
                                        : c.link === '/download.html'
                                        ? 'is-animation'
                                        : ''
                                    }
                                  >
                                    {c.title}
                                  </p>
                                  <p className='subtitle'>{c.text}</p>
                                </div>
                              </DropdownBlock>
                            )
                          })}
                          <QrCodeBlock>
                            <img src={QRCode} alt='' />
                            <div>
                              <p>微信扫一扫</p>
                              <p>随时随地监控渲染进度</p>
                              <p className='qrcode-text'>手机预览/重提/下载作业/充值订阅</p>
                            </div>
                          </QrCodeBlock>
                        </DropdownBlockWrap>
                      ) : (
                        <DropdownWrap>
                          {l.children.map((c, ci) => (
                            <NavigationLink
                              key={ci}
                              to={c.link}
                              title={c.text}
                              isActive={location.pathname === c.link}
                              target={c.target}
                              isOtherWeb={c.isOtherWeb}
                            >
                              {c.text}
                            </NavigationLink>
                          ))}
                        </DropdownWrap>
                      )}
                    </DropdownMenu>
                  ) : (
                    <NavigationLink
                      key={i}
                      to={l.link}
                      title={l.alt || l.text}
                      isActive={location.pathname === l.link}
                      target={l.target}
                      isOtherWeb={l.isOtherWeb}
                      rel={l.rel}
                    >
                      {l.text}
                    </NavigationLink>
                  ),
                )}
              </div>
            </Flex>
            <Flex>
              {isUserLogin ? (
                <AccountDetail>
                  <a href='https://task.renderbus.com'>控制台</a>
                  <DropdownMenu>
                    <UserMsg>
                      <UserAvatar>{currentUserName.substring(0, 1)}</UserAvatar>
                      <span>{currentUserName}</span>
                      <Icon type={IconType.ArrowRight} />
                    </UserMsg>
                    <DropdownWrap>
                      {Header.taskLinkList.map(({ link, text }, index) => (
                        <a key={index} href={link}>
                          {text}
                        </a>
                      ))}
                      <span onClick={this.redirectToAuth}>实名认证</span>
                      <span onClick={this.handleLogout}>退出登录</span>
                    </DropdownWrap>
                  </DropdownMenu>
                </AccountDetail>
              ) : (
                <AccountContainer>
                  <LoginButton>
                    <LoginLink href={`${baseLink()}/sso`} title='登录' rel='nofollow'>
                      登录
                    </LoginLink>
                  </LoginButton>
                  <RegisterButton>
                    <Button
                      as='a'
                      color='primary'
                      variant='outlined'
                      href={`${baseLink()}/sso/register`}
                      rel='nofollow'
                    >
                      注册
                    </Button>
                  </RegisterButton>
                </AccountContainer>
              )}
              <IconWrap className='show-at-mobile' onClick={this.handleMenuClick}>
                <MenuIcon isActive={!showMobileMenu} />
                <CloseIcon isActive={showMobileMenu} />
              </IconWrap>
            </Flex>
            <MobileMenu isExpand={showMobileMenu} location={location} />
          </StyledWrapper>
        </Navbar>
      </React.Fragment>
    )
  }
}

export default Header
