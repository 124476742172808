import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Index } from 'elasticlunr'

export const withSearchIndex = Com => {
  return props => {
    return (
      <StaticQuery
        query={graphql`
          query {
            siteSearchIndex {
              index
            }
          }
        `}
        render={data => {
          return (
            <Com {...props} numPerPage={12} searchQuery={Index.load(data.siteSearchIndex.index)} />
          )
        }}
      />
    )
  }
}
