import React from 'react'
import styled from 'styled-components'
import { Flex, Button } from '@renderbus/common/components'
import { typography, Media } from '@renderbus/common/theme'
import Coupon from '../images/coupon.png'

const AdBannerContainer = styled.div`
  position: relative;
  height: 15vh;
`
const AdBannerImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`
const AdBannerContent = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  a {
    font-size: ${typography.h3};
    font-weight: normal;
    padding: 0 2.5rem;
  }
  ${Media.lessThan(Media.large)} {
    font-size: ${typography.textSmall};
    a {
      font-size: ${typography.h4};
      padding: 0 2rem;
    }
  }
`
const Slogan = styled.div`
  color: white;
  p:first-of-type {
    font-size: 2.25rem;
    font-weight: bold;
    ${Media.lessThan(Media.large)} {
      font-size: ${typography.h4};
    }
  }
  p:last-of-type {
    ${Media.lessThan(Media.small)} {
      display: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    p {
      text-align: center;
    }
  }
`

const AdBanner = () => (
  <AdBannerContainer>
    <AdBannerContent>
      <Slogan>
        <p>领100元渲染券，体验渲染提速10倍！</p>
        <p>支持3ds Max，Maya，C4D，Blender，V-ray，Redshift，Arnold，Corona等主流CG软件和插件</p>
      </Slogan>
      <Button
        as='a'
        variant='outlined'
        href='https://task.renderbus.com/sso/register'
        rel='nofollow'
      >
        注册领取
      </Button>
    </AdBannerContent>
    <AdBannerImg src={Coupon} alt='40元渲染券' />
  </AdBannerContainer>
)

export default AdBanner
